import React, { useCallback, useReducer, useMemo, useEffect } from "react";
import PickUp from "../../drag-drop-template/pick-up";
import DropZone from "../../drag-drop-template/drop-zone";
import './style.scss';

type Props = {
    onComplete?: () => void
}

type Item = {
    value: string,
    label: string
}

type Basket = {
    id: string,
    answer?: string,
    items: string[]
}

type GameState = {
    baskets: Basket[],
    items: Item[]
}

const TaskGame114: React.FunctionComponent<Props> = ({ onComplete }) => {

    const [gameData, moveEgg] = useReducer((state: GameState, { itemId, basket }: { itemId: string, basket: string }) => {
        return {
            ...state,
            baskets: state.baskets.map(b => {

                const items = b.items.filter(i => i !== itemId);

                if (b.id === basket) {
                    if (state.items.find(i => i.value === itemId)) {
                        items[0] = itemId;
                    }
                }

                return {
                    ...b,
                    items
                }
            })
        }
    }, {
        baskets: [
            {
                id: 'b1',
                answer: 'i7',
                items: []
            },
            {
                id: 'b2',
                answer: 'i1',
                items: []
            },
            {
                id: 'b3',
                answer: 'i3',
                items: []
            },
            {
                id: 'b4',
                answer: 'i6',
                items: []
            },
            {
                id: 'b5',
                answer: 'i4',
                items: []
            },
            {
                id: 'b6',
                answer: 'i2',
                items: []
            },
            {
                id: 'b7',
                answer: 'i5',
                items: []
            },
            {
                id: 'b8',
                answer: 'i5',
                items: []
            },
            {
                id: 'b9',
                answer: 'i5',
                items: []
            },
            {
                id: 'b10',
                answer: 'i5',
                items: []
            },
            {
                id: 'b11',
                answer: 'i5',
                items: []
            },
            {
                id: 'b12',
                answer: 'i5',
                items: []
            }
            

        ],
        items: [
            {
                value: 'i1',
                label: 'pobudka'
            },
            {
                value: 'i2',
                label: 'przygotowania do szkolnego dnia'
            },
            {
                value: 'i3',
                label: 'lekcje'
            },
            {
                value: 'i4',
                label: 'lekcje'
            },
            {
                value: 'i5',
                label: 'lekcje'
            },
            {
                value: 'i6',
                label: 'zajęcia dodatkowe'
            },
            {
                value: 'i7',
                label: 'odrabianie pracy domowej'
            },
            {
                value: 'i8',
                label: 'odpoczynek'
            },
            {
                value: 'i9',
                label: 'sen'
            },
            {
                value: 'i10',
                label: 'przerwa'
            },
            {
                value: 'i11',
                label: 'przerwa'
            },
            {
                value: 'i12',
                label: 'przerwa'
            }
        ]
    })

    // const completed = useMemo(() => {
    //     return gameData.baskets.reduce((prev, b) => {
    //         return prev && (b.answer === b.items[0]);
    //     }, true);
    // }, [gameData.baskets]);

    // useEffect(() => {
    //     if (completed) {
    //         if (onComplete) onComplete();
    //     }
    // }, [completed, onComplete])

    const onDrop = useCallback((pickupData: string, dropId: string) => {
        moveEgg({ itemId: pickupData, basket: dropId });
    }, [])

    const isUnused = useCallback((value) => {
        return gameData.baskets.reduce((prev, next) => {
            return prev && Boolean(next.items[0] !== value);
        }, true);
    }, [gameData.baskets])


    return (
        <div className="task-game-114"> 
            <div className="pick">
                {
                    isUnused('i1') && <GameElement isPickable={true} item={gameData.items.find(i => i.value === 'i1')}></GameElement>
                }
            </div>         
            <GameDropZone id="b1" game={gameData} onDrop={onDrop}></GameDropZone>
            <div className="pick">
                {
                    isUnused('i2') && <GameElement isPickable={true} item={gameData.items.find(i => i.value === 'i2')}></GameElement>
                }
            </div>
            <GameDropZone id="b2" game={gameData} onDrop={onDrop}></GameDropZone>
            <div className="pick">
                {
                    isUnused('i3') && <GameElement isPickable={true} item={gameData.items.find(i => i.value === 'i3')}></GameElement>
                }
            </div>
            <GameDropZone id="b3" game={gameData} onDrop={onDrop}></GameDropZone>
            <div className="pick">
                {
                    isUnused('i4') && <GameElement isPickable={true} item={gameData.items.find(i => i.value === 'i4')}></GameElement>
                }
            </div>            
            <GameDropZone id="b4" game={gameData} onDrop={onDrop}></GameDropZone>
            <div className="pick">
                {
                    isUnused('i5') && <GameElement isPickable={true} item={gameData.items.find(i => i.value === 'i5')}></GameElement>
                }
            </div>
            <GameDropZone id="b5" game={gameData} onDrop={onDrop}></GameDropZone>
            <div className="pick">
                {
                    isUnused('i6') && <GameElement isPickable={true} item={gameData.items.find(i => i.value === 'i6')}></GameElement>
                }
            </div>  
            <GameDropZone id="b6" game={gameData} onDrop={onDrop}></GameDropZone>
            <div className="pick">
                {
                    isUnused('i7') && <GameElement isPickable={true} item={gameData.items.find(i => i.value === 'i7')}></GameElement>
                }
            </div>
            <GameDropZone id="b7" game={gameData} onDrop={onDrop}></GameDropZone>
            <div className="pick">
                {
                    isUnused('i8') && <GameElement isPickable={true} item={gameData.items.find(i => i.value === 'i8')}></GameElement>
                }
            </div>
            <GameDropZone id="b8" game={gameData} onDrop={onDrop}></GameDropZone>
            <div className="pick">
                {
                    isUnused('i9') && <GameElement isPickable={true} item={gameData.items.find(i => i.value === 'i9')}></GameElement>
                }
            </div>
            <GameDropZone id="b9" game={gameData} onDrop={onDrop}></GameDropZone>
            <div className="pick">
                {
                    isUnused('i10') && <GameElement isPickable={true} item={gameData.items.find(i => i.value === 'i10')}></GameElement>
                }
            </div>
            <GameDropZone id="b10" game={gameData} onDrop={onDrop}></GameDropZone>
            <div className="pick">
                {
                    isUnused('i11') && <GameElement isPickable={true} item={gameData.items.find(i => i.value === 'i11')}></GameElement>
                }
            </div>
            <GameDropZone id="b11" game={gameData} onDrop={onDrop}></GameDropZone>
            <div className="pick">
                {
                    isUnused('i12') && <GameElement isPickable={true} item={gameData.items.find(i => i.value === 'i12')}></GameElement>
                }
            </div>
            <GameDropZone id="b12" game={gameData} onDrop={onDrop}></GameDropZone>
        </div>
    )
}

const GameElement: React.FunctionComponent<{ item?: Item, isPickable: boolean }> = ({ item, isPickable }) => {

    return (
        <>
            {item && <div className="game-element">
                {isPickable && <PickUp
                    data={item.value}
                >
                    <p>{item.label}</p>
                </PickUp>}
                {!isPickable && <p>{item.label}</p>}
            </div>}
        </>
    )
}

const GameDropZone: React.FunctionComponent<{
    id: string,
    game: GameState,
    onDrop: (pickupData: string, dropId: string) => void;
}> = ({ id, onDrop, game, children }) => {

    const basket = game.baskets.find(b => b.id === id);
    const itemValue = basket?.items[0] || null;
    const item = game.items.find(i => i.value === itemValue) || null;

    const state = useMemo(() => {
        if (!item) {
            return 'empty'
        }
        return 'not-empty';
    }, [basket, item])

    return (
        <div className={`game-drop-zone ${state}`}>
            <div className="inner">
                <DropZone onDrop={onDrop} id={id}>
                    {
                        item && <GameElement item={item} isPickable={true} ></GameElement>
                    }
                    {
                        !item && <div className="placeholder"><p>przeciągnij tutaj</p></div>
                    }
                </DropZone>
            </div>
        </div>
    )
}

export default TaskGame114;
