import CenterLolumnLayout from "../../layout/screen";
import React, { useEffect, useCallback, useRef } from "react";

// Ponieważ hook dla ie11 nie ma styli zostawiam tutaj zwykłego hooka do developmentu
// import { useForm } from "react-hook-form";

//@ts-ignore
import { useForm } from "react-hook-form/dist/react-hook-form.ie11";


import { useHistory } from "react-router";
import useSetUserName from "../../modules/user/hooks/use-set-user-name";
import { getMapRoute } from "../../routes";
import './style.scss';
import PageFooter from "../../components/page-footer";
import useSetUserEmblem from "../../modules/user/hooks/use-set-user-emblem";
import useUser from "../../modules/user/hooks/use-user";
import { Link } from "react-router-dom";

export default function LoginPage() {
    const { register, handleSubmit, errors } = useForm();
    const history = useHistory();
    const setUserName = useSetUserName();
    const setUserEmblem = useSetUserEmblem();

    const { name } = useUser();

    const onSubmit = useCallback((data: any) => {
        setUserName(data.name);
        setUserEmblem(data.emblem);
        history.push(getMapRoute());
    }, [history, setUserName, setUserEmblem])

    const inputName = useRef<HTMLElement | null>(null);

    useEffect(() => {
        document.body.classList.add('login-page');

        return () => {
            document.body.classList.remove('login-page');
        }
    })


    return (
        <CenterLolumnLayout footer={PageFooter()}>
            <main className="login-screen">                
                <form onSubmit={handleSubmit(onSubmit)}>
                    <h1>Podaj swoje imię</h1>
                    <div className="name">
                        <div className="input-name-container">
                            <input
                                defaultValue={name}
                                type="text"
                                id="name"
                                name="name"
                                autoComplete={'off'}
                                placeholder='Wpisz swoje imię'
                                ref={(e) => {
                                //@ts-ignore
                                register(e, { required: true })
                                inputName.current = e;
                            }} />
                        </div>
                        {errors.name && <p className="error">Wypełnienie zgłoszenia jest obowiązkowe</p>}
                    </div>
                    <h1>Wybierz avatar</h1>
                    
                    <fieldset className="emblem">
                        <div className="emblem-list">
                            <input type="radio" id="emblem-1" name="emblem" value="awatar1" aria-label="Awatar 1" ref={register} />
                            <label htmlFor="emblem-1" className="emblem-1" aria-label="Awatar" >
                                <img src="./images/awatars/1.png" alt="Awatar" />
                            </label>

                            <input type="radio" id="emblem-2" name="emblem" value="awatar2" aria-label="Awatar 2" ref={register} />
                            <label htmlFor="emblem-2" className="emblem-2" aria-label="Awatar">
                                <img src="./images/awatars/2.png" alt="Awatar" />
                            </label>

                            <input type="radio" id="emblem-3" name="emblem" value="awatar3" aria-label="Awatar 3" ref={register} />
                            <label htmlFor="emblem-3" className="emblem-3" aria-label="Awatar">
                                <img src="./images/awatars/3.png" alt="Awatar" />
                            </label>

                            <input type="radio" id="emblem-4" name="emblem" value="awatar4" aria-label="Awatar 4" ref={register} />
                            <label htmlFor="emblem-4" className="emblem-4" aria-label="Awatar">
                                <img src="./images/awatars/4.png" alt="Awatar" />
                            </label>
                        </div>
                        <div className="emblem-list">
                            <input type="radio" id="emblem-5" name="emblem" value="awatar5" aria-label="Awatar 5" ref={register} />
                            <label htmlFor="emblem-5" className="emblem-1" aria-label="Awatar" >
                                <img src="./images/awatars/5.png" alt="Awatar" />
                            </label>

                            <input type="radio" id="emblem-6" name="emblem" value="awatar6" aria-label="Awatar 6" ref={register} />
                            <label htmlFor="emblem-6" className="emblem-2" aria-label="Awatar">
                                <img src="./images/awatars/6.png" alt="Awatar" />
                            </label>

                            <input type="radio" id="emblem-7" name="emblem" value="awatar7" aria-label="Awatar 7" ref={register} />
                            <label htmlFor="emblem-7" className="emblem-3" aria-label="Awatar">
                                <img src="./images/awatars/7.png" alt="Awatar" />
                            </label>

                            <input type="radio" id="emblem-8" name="emblem" value="awatar8" aria-label="Awatar 8" ref={register} />
                            <label htmlFor="emblem-8" className="emblem-4" aria-label="Awatar">
                                <img src="./images/awatars/8.png" alt="Awatar" />
                            </label>
                        </div>
                    </fieldset>

                            

                    <div className="button-container">
                        <button type="submit" name="submit" className="button-image button-next"><span>Dalej</span></button>
                    </div>
            
                </form>
            </main>
            
        </CenterLolumnLayout>
    )
}
