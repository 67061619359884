import React from "react";
import './style-tile.scss';

type Props = {
    image: string,
    back: string,
    isHidden: boolean,
    isRemoved: boolean,
    isMismatched: boolean,
    onSelected: () => void
}

export default function MemoryTile({image, back, isHidden, isRemoved, isMismatched, onSelected}: Props) {
    return (
        <div className="tile-container">
            <img
                className={`tile-image ${isRemoved ? 'removed' : ''} ${isMismatched ? 'mismatched' : ''} ${isHidden ? 'hidden' : ''}`}
                src={isHidden ? back : image}
                onClick={onSelected}
                alt="TODO"
            />
        </div>
    )
}
