import React, { useMemo, useState, useCallback, useReducer, useEffect } from "react";
import MemoryGrid from "./memory-grid";


enum GAME_PHASE {
    WAITING = 'WAITING',
    COMPARING = 'COMPARING',
    ENDED = 'ENDED'
}

export default function MemoryGame() {

    const gridWidth = 4;
    const gridHeight = 4;

    const showGameInitError = Boolean((gridHeight * gridWidth) % 2);
    const showGame = !showGameInitError;

    const images = useMemo(() => [
        '/tasks/01/memo/indyk.png',
        '/tasks/01/memo/jednorozec.png',
        '/tasks/01/memo/jelen.png',
        '/tasks/01/memo/lew.png',
        '/tasks/01/memo/niedzwiedz.png',
        '/tasks/01/memo/slon.png',
        '/tasks/01/memo/wielblad.png',
        '/tasks/01/memo/wiewiorka.png',
    ], []);

    const back = '/tasks/01/memo/back.png'

    const [tiles, setTiles] = useState(() => {
        return images.reduce<TileModel[]>((prev, next, index) => {
            return [
                ...prev,
                { value: index, image: next, isHidden: true, isRemoved: false, isMismatched: false },
                { value: index, image: next, isHidden: true, isRemoved: false, isMismatched: false }
            ].sort(() => Math.random() - .5)
        }, [])
    })

    const [phase, setPhase] = useState<GAME_PHASE>(GAME_PHASE.WAITING);

    const [activeTiles, setActiveTile] = useReducer((state: number[], value: number | null) => {

        if (value === null) {
            return [];
        }

        if (value === state[0]) {
            return state;
        }

        if (state.length < 2) {
            return [
                ...state,
                value
            ]
        }

        return state;
    }, []);

    const onTileSelected = useCallback((tile: number) => {

        if (phase !== GAME_PHASE.WAITING) return;

        // Nie reagujemy na kliknięcia w odsłonięte płyki.
        if (!tiles[tile].isHidden) return;

        // Nie reagujemy na kliknięcia w usuniete płytki.
        if (tiles[tile].isRemoved) return;

        setActiveTile(tile);

    }, [phase, setActiveTile, tiles])

    useEffect(() => {
        const newTiles = [
            ...tiles
        ]

        let dirty = false;

        activeTiles.forEach(t => {

            if (newTiles[t].isHidden) {
                newTiles[t].isHidden = false;
                dirty = true;
            }
        })

        if (dirty) setTiles(newTiles);
    }, [activeTiles, tiles, setTiles])

    useEffect(() => {
        if (activeTiles.length !== 2) return;

        // Jeżeli odkryliśmy 2 kafelki porównujemy ich wartości
        setPhase(GAME_PHASE.COMPARING);
        let id: number;

        const newTiles = [
            ...tiles
        ]

        // Jeżeli wartości się zgadzają
        if (tiles[activeTiles[0]].value === tiles[activeTiles[1]].value) {
            tiles[activeTiles[0]].isHidden = false;
            tiles[activeTiles[0]].isRemoved = true;
            tiles[activeTiles[1]].isHidden = false;
            tiles[activeTiles[1]].isRemoved = true;


            setActiveTile(null)
            setTiles(newTiles);
            setPhase(GAME_PHASE.WAITING);

        } else {
            id = window.setTimeout(() => {
                tiles[activeTiles[0]].isMismatched = true;
                tiles[activeTiles[1]].isMismatched = true;
                setActiveTile(null)
                setTiles(newTiles);

                setTimeout(() => {
                    tiles[activeTiles[0]].isHidden = true;
                    tiles[activeTiles[1]].isHidden = true;
                    tiles[activeTiles[0]].isMismatched = false;
                    tiles[activeTiles[1]].isMismatched = false;

                    setPhase(GAME_PHASE.WAITING);
                }, 1000)
            }, 500);
        }

        return () => clearTimeout(id);

    }, [activeTiles, setPhase, tiles, setTiles])

    useEffect(() => {
        if (tiles.every(t => t.isHidden === false)) {
            setPhase(GAME_PHASE.ENDED);
        }
    }, [tiles])

    return (
        <>
            {
                showGameInitError &&
                <p>Błędne dane wejściowe gry</p>
            }
            {
                showGame &&
                <div style={{width:'95%', maxWidth: '1000px'}}>
                    <MemoryGrid
                        gridWidth={gridWidth}
                        gridHeight={gridHeight}
                        models={tiles}
                        back={back}
                        onTileSelected={onTileSelected}
                    ></MemoryGrid>
                </div>
            }
        </>
    )
}

export type TileModel = {
    value: number,
    image: string,
    isHidden: boolean,
    isRemoved: boolean,
    isMismatched: boolean
}
