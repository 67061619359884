import React, { useCallback, useState } from "react";
import './style.scss';

type Props = {
    onComplete?: () => void
}

const TaskGame135: React.FunctionComponent<Props> = () => {

    return (
        <div className="task-game-135">
            <div className="hustawka">
                <img src={process.env.PUBLIC_URL + '/tasks/task-135/hustawka.png'}/>
                <div className="item item-1">
                    <label className="checkbox-container">
                        <input type="checkbox" />&nbsp;
                        <span className="checkmark"></span>
                    </label>
                </div>
                <div className="item item-2">                    
                    <label className="checkbox-container">
                        <input type="checkbox" />&nbsp;
                        <span className="checkmark"></span>
                    </label>
                </div>
                <div className="item item-3"> 
                    <label className="checkbox-container">
                        <input type="checkbox" />&nbsp;
                        <span className="checkmark"></span>
                    </label>
                </div>         
                <div className="item item-4"> 
                    <label className="checkbox-container">
                        <input type="checkbox" />&nbsp;
                        <span className="checkmark"></span>
                    </label>
                </div>      
                <div className="item item-5"> 
                    <label className="checkbox-container">
                        <input type="checkbox" />&nbsp;
                        <span className="checkmark"></span>
                    </label>
                </div>     
            </div>            
        </div>
    )
}

export default TaskGame135;
