import React from "react";
import CenterLolumnLayout from "../../layout/screen";
import PageFooter from '../../components/page-footer';
import { getManualRoute } from '../../routes';
import './style.scss';
import { Link } from "react-router-dom";

const CategoriesPage: React.FunctionComponent = () => {
    return (
        <CenterLolumnLayout footer={PageFooter()}>
            <main className='column categories-screen'>

                <h1>Kategorie zadań</h1>

                <div className="category category-1">
                    <div className="icon"></div>
                    <h3>Biurko</h3>
                    <p>
                        bo tutaj spędzasz najwięcej czasu. To Twoje nowe miejsce dowodzenia, nauki, utrzymywania relacji z nauczycielami i nauczycielkami, 
                        przyjacielskich pogaduszek z kolegami i koleżankami z klasy. Biurko to serce szkoły w domu, więc musi być zadbane i dobrze na Ciebie 
                        wpływać, tak samo jak przestrzeń Twojej ulubionej sali w szkole.
                    </p>
                </div>

                <div className="category category-2">
                    <div className="icon"></div>
                    <h3>Komputer</h3>
                    <p>
                        bo dobrze zorganizowane dane w komputerze, łatwy dostęp do programu do rozmów z klasą to namiastka życia w prawdziwej klasie, gdzie 
                        naturalne są spotkania ze znajomymi na przerwach i szybki dostęp do zeszytu i podręcznika. W komputerze też dzieje się architektura 
                        uczenia się, tylko bardziej wirutalnie :) Dobrze zaplanowane miejsce pracy sprawi, że  nauka będzie skuteczniejsza. 
                    </p>
                </div>

                <div className="category category-3">
                    <div className="icon"></div>
                    <h3>świat za oknem</h3>
                    <p>
                        bo szkoła w domu to też odejście od komputera, rozglądanie się po okolicy przez okno, odkrywanie na nowo swojego podwórka, machanie 
                        z daleka do sąsiadów i pomaganie tym, którzy nie mogą wyjść z domu. 
                    </p>
                </div>

                <div className="category category-4">
                    <div className="icon"></div>
                    <h3>zabawa w domu</h3>
                    <p>
                        bo tak jak w szkole, pomiędzy intensywnymi lekcjami nadchodzi wyczekiwana przerwa i chwila oddechu. Bez zabawy w czasie dnia szkolnego 
                        nie ma skutecznej nauki. To dwa niezbędne elementy, więc jak o nie zadbać w domu? Czas odkryć pokłady możliwości w znanych sobie czterech 
                        ścianach, do tego włączymy w to rodziców i innych domowników!
                    </p>
                </div>                

                <div className="category category-5">
                    <div className="icon"></div>
                    <h3>"pamiętając szkołę"</h3>
                    <p>
                        bo w końcu nadejdzie normalność i powrót do standardowej szkoły. Ale czy to będzie ta sama szkoła i te same zasady? To już zależy od Was! 
                        Liczymy, że zadaniami wyzwolimy w Was przemyślenia na temat jak szkoła się powinna zmienić po powrocie do zajęć? Czy z lekcji zdalnych 
                        wyciągnęliśmy jakieś… lekcje? Jaką szkołę chcemy spotkać po powrocie? Jak było i co może się zmienić? Czym w ogóle jest szkoła? 
                        Czy to tylko budynek, określona przestrzeń, czy może coś więcej? 
                    </p>
                </div>  
            </main>
            <nav className="home-nav">
                <div>
                    <Link to={getManualRoute()} 
                        className='button-image button-next'>
                        <span>Dalej</span>
                    </Link>
                </div>
            </nav>
        </CenterLolumnLayout>
    )
}

export default CategoriesPage;
