import Dyplom from "./dyplom";

export type AppConfig = {
    userName: string,
    container: HTMLElement
    awardsData: {
        id: string,
        src: string,
        iconSrc: string,
        active: boolean
    }[]
}

export default function createApp(config: AppConfig) {

    const app = new Dyplom(config)

    config.container.appendChild(app.view);
    app.gameResize();

    // ====================== //

    return {
        getSceenBlob: () => app.getSceenBlob(),
        uninstall: () => {
            app.destroy();
        }
    };
}
