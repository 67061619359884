export function getHomeRoute() {
    return '/';
}

export function getMapRoute() {
    return '/mapa'
}

export function getTaskRoute(id: string | number = ':id') {

    return taskIDToUri(String(id)) || `/zadanie/${id}`
}

export function getManualRoute() {
    return '/instrukcja';
}

export function getCategoriesRoute() {
    return '/kategorie';
}

export function getRulesRoute() {
    return '/zasady';
}

export function getLoginRoute() {
    return '/zapisy';
}

export function getEndRoute() {
    return '/koniec'
}


export const TASK_TO_URI_TABLE: [string, string][] = []

export function taskIDToUri(id: string): string | null {
    const reference = TASK_TO_URI_TABLE.find(data => data[0] === id);
    return reference? reference[1] : null;
}
