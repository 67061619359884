import React, { useMemo } from "react";
import styled from "styled-components";
import { TileModel } from ".";
import MemoryTile from "./memory-tile";

type Props = {
    gridWidth: number,
    gridHeight: number,
    models: TileModel[],
    back: string,
    onTileSelected: (t: number) => void
}

interface GridProps {
    gridWidth: number,
    gridHeight: number,

}

const Grid = styled.div<GridProps>`
    display: flex;
    flex-wrap: wrap;
    & > * {
        width: ${(props) => {return 100 / props.gridWidth}}%;
    }
`

export default function MemoryGrid({ gridWidth, gridHeight, models, back, onTileSelected }: Props) {

    const tiles = useMemo(() => {
        return models.map((m, index) => {
            return (
                <MemoryTile
                    key={index}
                    image={m.image}
                    back={back}
                    isHidden={m.isHidden}
                    isRemoved={m.isRemoved}
                    isMismatched={m.isMismatched}
                    onSelected={() => onTileSelected(index)}
                />
            )
        })

    }, [
        back, models, onTileSelected
    ])

    return (
        <Grid
            gridWidth={gridWidth}
            gridHeight={gridHeight}
        >
            {tiles}
        </Grid>
    )
}
