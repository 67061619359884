import React from "react";
import { DemoModuleProvider } from "./demo";
import { GameModuleProvider } from "./game";
import { UserModuleProvider } from "./user";
import { MainModuleProvider } from "./main";

const providersList: React.FunctionComponent<{}>[] = [
    DemoModuleProvider,
    GameModuleProvider,
    UserModuleProvider,
    MainModuleProvider
]

const ModulesProviders: React.FunctionComponent = ({ children }) => {
    return providersList.reduce((Prev, Next) => {
        return (
            <Next>
                {Prev}
            </Next>
        )
    }, (<>{children}</>))
}

export default ModulesProviders;
