import { useUserModuleDispatch, USER_MODULE_ACTION } from "..";
import { useCallback } from "react";

export default function useSetUserName() {
    const dispatch = useUserModuleDispatch();

    const setUserName = useCallback((name: string) => {
        dispatch({
            type: USER_MODULE_ACTION.SET_NAME,
            payload: {
                name
            }
        })
    }, [dispatch])

    return setUserName
}
