//@ts-ignore
import * as sanitizeHtml from 'sanitize-html';
import React, { useMemo } from 'react';
import TaskElements from './taks-elements';

type DTProps = {
    task: Task
}

const DefaultTask: React.FunctionComponent<DTProps> = ({ task }) => {

    const description: string[] = task ? (typeof task.description === 'string' ? [task.description] : task.description) : [];

    const classLetter = useMemo(() => {
        const l = task?.title[0].toLowerCase();

        return l ? l.replace('ż', 'z2') : '';
    }, [task]);

    return (

        <div className="task-screen column">

            <h1>{task?.title}</h1>

            {description.map((d, index) => {
                return (
                    <p key={index} dangerouslySetInnerHTML={{ __html: sanitizeHtml(d) }}>
                    </p>
                )
            })
            }

            <TaskElements
                elements={task?.elements || []}
            />
        </div >)
}

export default DefaultTask;
