import React, { useEffect } from "react";
import './style.scss';
import { useAcceptCookies } from "../../modules/main";

export default function Cookies() {

    const acceptCookies = useAcceptCookies();

    useEffect(() => {
        window.document.body.classList.add('with-cookies');

        return () => {window.document.body.classList.remove('with-cookies');}
    })

    return (
        <aside className="cookies-overlay">
            <div className="container">
                <div className="inner">
                    <p className="info">
                        Nasza strona wykorzystuje pliki cookies. <a href="https://ceo.org.pl/sites/default/files/polityka_prywatnosci_ceo.pdf">Polityka prywatności</a>
                    </p>
                    <div className='buttons'>
                        <button className="button" onClick={acceptCookies}>Zgadzam się</button>
                    </div>
                </div>


            </div>
        </aside>
    )
}
