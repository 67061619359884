import React, { useEffect } from 'react';

import CenterLolumnLayout from '../../layout/screen';
import './style.scss';
import { Link } from 'react-router-dom';
import PageFooter from '../../components/page-footer';
import { getCategoriesRoute } from '../../routes';
import { useSetPatternBackground } from "../../layout/background";


export default function HomePage() {
    const setBackground = useSetPatternBackground();

    useEffect(() => {
        setBackground(`/images/backgrounds/pattern_1.png`)

        document.body.classList.add('home-page');

        return () => {
            document.body.classList.remove('home-page');
        }
    })

    return (
        <CenterLolumnLayout footer={PageFooter()}>
            <main className='column home-screen'>
                <h1>Witajcie!</h1>
                <p>
                    Szkoła dzieje się teraz w domu. Początkowo wszystkich nas to zaskoczyło, ale z upływem czasu przyzwyczailiśmy się do tej sytuacji.<br /> 
                    W poprzednich edycjach Projektu Szkoła zajmowaliśmy się tematem dobrej przestrzeni do uczenia się w klasie, świetlicy, bibliotece szkolnej. <br />
                    Więc teraz, kiedy wszystkie szkolne aktywności i wspomnienia przechodzą do domu, chcemy wspólnie zastanowić się co to znaczy "szkoła w domu".<br /> 
                    Jak mądrze i bezpiecznie zorganizować swoją domową przestrzeń do nauki? 
                    Jak zadbać o odpoczynek? I w końcu - jak przygotować się do powrotu do szkoły z głową pełną pomysłów?
                </p>
            </main>
            <nav className="home-nav">
                <div>
                    <Link to={getCategoriesRoute()} 
                        className='button-image button-adventure'>
                        <span>Rozpocznij przygodę</span>
                    </Link>
                </div>
            </nav>
        </CenterLolumnLayout>
    )
}
