import React, { useCallback, useState } from "react";
import './style.scss';

type Props = {
    onComplete?: () => void
}

const TaskGame113: React.FunctionComponent<Props> = () => {

    const [chairHeight, setChairState] = useState(0);

    const chlopak = process.env.PUBLIC_URL + '/tasks/task-113/chlopak.png'
    const chairs = [
        process.env.PUBLIC_URL + '/tasks/task-113/krzeslo_1.png',
        process.env.PUBLIC_URL + '/tasks/task-113/krzeslo_2.png',
        process.env.PUBLIC_URL + '/tasks/task-113/krzeslo_3.png'
    ]

    const clickUp = useCallback(() => {
        if (chairHeight < 2) {
            setChairState(chairHeight+1);
        }        
    }, [setChairState, chairHeight]);

    const clickDown = useCallback(() => {
        if (chairHeight > 0) {
            setChairState(chairHeight-1);
        }   
    }, [setChairState, chairHeight]);

    return (
        <div className="task-game-113">
            <div className="column stering-buttons">
                <button className="button-stering-up button-image button-up" onClick={clickUp}>
                </button>
                <button className="button-stering-down button-image button-down" onClick={clickDown}></button>
                <img src={chairs[chairHeight]} alt="" />
            </div>
            <div className="column align-left">
                <img src={chlopak} alt="" />
            </div>
     
        </div>
    )
}

export default TaskGame113;
