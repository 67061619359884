import React, { useCallback, useReducer } from "react";
import PickUp from "./pick-up";
import DropZone from "./drop-zone";
import './style.scss';

type Props = {}

type Basket = {
    label: string
    items: string[]
}

const DnDTemplate: React.FunctionComponent<Props> = () => {

    const [gameData, moveEgg] = useReducer((state: Basket[], { egg, basket }: { egg: string, basket: string }) => {
        return [
            ...state.map(b => {

                const items = b.items.filter(i => i !== egg);

                if (b.label === basket) {
                    items.push(egg);
                }

                return {
                    ...b,
                    items
                }
            })
        ]
    }, [{
        label: 'koszyk-1',
        items: ['egg1', 'egg2']
    }, {
        label: 'koszyk-2',
        items: []
    },
    ])

    const onDrop = useCallback((pickupData: string, dropId: string) => {
        moveEgg({egg: pickupData, basket: dropId});
    }, [])


    return (
        <div className="dnd-template">
            <h1>Przeciagarka DEMO</h1>

            {
                gameData.map((basket, index) => {
                    return  (
                        <div key={index}>
                            <GameDropZone onDrop={onDrop} label={basket.label}>
                                {basket.items.map((egg, index) => (
                                    <GameElement key={index} label={egg} isPickable={true} />
                                ))}
                            </GameDropZone>

                        </div>
                    )
                })
            }
        </div>
    )
}

const GameElement: React.FunctionComponent<{ label: string, isPickable: boolean }> = ({ label, isPickable }) => {

    return (
        <>
            <PickUp
                data={label}
            >
                <span className="game-element">{label}</span>
            </PickUp>
        </>
    )
}

const GameDropZone: React.FunctionComponent<{
    label: string,
    onDrop: (pickupData: string, dropId: string) => void;
}> = ({ label, onDrop, children }) => {
    return (
        <div>
            <h3>{label}</h3>
            <DropZone onDrop={onDrop} id={label}>{children}</DropZone>
        </div>
    )
}

export default DnDTemplate;
