import * as PIXI from 'pixi.js'
import { GameConfig } from './game';
import { Sticker } from './sticker';

export default class Korony extends PIXI.Application {
    private baseWidth: number;
    private baseHeight: number;
    private baseRatio: number;
    private scaleRatio = 1;
    private resources?: Partial<Record<string, PIXI.LoaderResource>>;

    constructor(width: number, height: number, backgroundColor: number, private config: GameConfig) {
        super({
            width,
            height,
            backgroundColor
        });

        this.baseHeight = height;
        this.baseWidth = width;
        this.baseRatio = height / width;

        window.addEventListener('resize', this.gameResize)

        this.loadTextures().then((resources) => {
            this.resources = resources;
            this.initGame()
        })
    }

    private loadTextures(): Promise<Partial<Record<string, PIXI.LoaderResource>>> {
        return new Promise((resolve) => {
            [
                ['macius', '../tasks/task-111/stol.png'],
                ['k1', '../tasks/task-111/laptop.png'],
                ['k2', '../tasks/task-111/ksiazki.png'],
                ['k3', '../tasks/task-111/filizanka.png'],
                ['k4', '../tasks/task-111/lampa.png'],
                ['k5', '../tasks/task-111/zeszyt.png'],
                ['k6', '../tasks/task-111/kubek.png']
            ].forEach(([key, src]) => {
                this.loader.add(key, src);
            })

            this.loader.load((loader, resource) => {
                resolve(resource)
            })

            this.loader.onError.add((...args: any[]) => {
                console.error(...args);
            })
        })

    }

    private initGame() {
        this.initMacius();
        this.initStickers();
    }

    private initMacius() {
        if (this.resources?.macius) {
            const macius = new PIXI.Sprite(this.resources.macius.texture)

            macius.anchor.set(.5, 1);

            macius.x = this.baseWidth/2;
            macius.y = this.baseHeight;

            this.stage.addChild(macius);
        }
    }

    private initStickers() {
        if (this.resources) {
        const k = ['k1', 'k2', 'k3', 'k4', 'k5', 'k6'].map(key => {
                const sticker = new Sticker(
                    //@ts-ignore
                    this.resources[key].texture,
                    100, 100,
                    key
                )                
           
                this.stage.addChild(sticker);

                return sticker
            })

            k[0]?.position.set(500,300)
            k[1]?.position.set(800,400)
            k[2]?.position.set(500,450)
            k[3]?.position.set(200,300)
            k[4]?.position.set(300,450)
            k[5]?.position.set(970,300)
        }
    }

    public gameResize = () => {
        const nW = this.config.container.offsetWidth;
        const nH = nW * this.baseRatio;

        this.renderer.resize(nW, nH);

        this.scaleRatio = (nW / this.baseWidth);

        this.stage.scale.set(this.scaleRatio)
    }

    public getSceenBlob() {
            const data = this.renderer.extract.canvas(this.stage).toDataURL('image/png', 1);
            return data;
    }
}
