import { UserModuleState, UserModuleAction, USER_MODULE_ACTION, initUserModuleState } from ".";

export default function UserReducer(state: UserModuleState, action: UserModuleAction): UserModuleState {

    switch (action.type) {
        case USER_MODULE_ACTION.SET_NAME:
            return {
                ...state,
                name: action.payload.name
            }

        case USER_MODULE_ACTION.SET_EMBLEM:
            return {
                ...state,
                emblem: action.payload.emblem
            }

        case USER_MODULE_ACTION.COMPLETE_TASK:

            if (state.completedTasks.indexOf(action.payload.id) === -1) {
                return {
                    ...state,
                    completedTasks: [
                        ...state.completedTasks,
                        action.payload.id
                    ]
                }
            }

            return state;


        case USER_MODULE_ACTION.RESET:
            return {
                ...initUserModuleState
            }
    }

    return state;
}
