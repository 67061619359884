import React, { useCallback, useState } from "react";
import './style.scss';

type Props = {
    onComplete?: () => void
}

const TaskGame121: React.FunctionComponent<Props> = () => {

    return (
        <div className="task-game-121">
            <div>
                <label>Plik ze zdjęciami z wakacji - włochy232342.jpg</label>
                <input type="text" placeholder="wpisz tutaj"></input>
            </div>
            <div>
                <label>Plik z opowiadaniem na język - polski basn2020.doc</label>
                <input type="text" placeholder="wpisz tutaj"></input>
            </div>
            <div>
                <label>Plik z wyliczeniami na matematykę - 32323.xlsx</label>
                <input type="text" placeholder="wpisz tutaj"></input>
            </div>
        </div>
    )
}

export default TaskGame121;
