import React, { useCallback, useState } from "react";
import './style.scss';

type Props = {
    onComplete?: () => void
}

const TaskGame115: React.FunctionComponent<Props> = () => {

    const [chairHeight, setChairState] = useState(0);
    
    const chairs = [
        process.env.PUBLIC_URL + '/tasks/task-115/stol_1.png',
        process.env.PUBLIC_URL + '/tasks/task-115/stol_2.png',
        process.env.PUBLIC_URL + '/tasks/task-115/stol_3.png',
        process.env.PUBLIC_URL + '/tasks/task-115/stol_4.png',
        process.env.PUBLIC_URL + '/tasks/task-115/stol_5.png',

    ]

    const clickLeft = useCallback(() => {
        if (chairHeight > 0) {
            setChairState(chairHeight-1);
        }  
        
    }, [setChairState, chairHeight]);

    const clickRight = useCallback(() => {
        if (chairHeight < 4) {
            setChairState(chairHeight+1);
        }        
    }, [setChairState, chairHeight]);

    return (
        <div className="task-game-115">
            <div className="stering-buttons">
                <button className="button-stering-left button-image button-left" onClick={clickLeft}></button>
                <button className="button-stering-right button-image button-right" onClick={clickRight}></button>
                <img src={chairs[chairHeight]} alt="" />
            </div>            
        </div>
    )
}

export default TaskGame115;
