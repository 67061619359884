import { GameModuleState, GameModuleAction, GAME_MODULE_ACTION } from ".";

export default function GameReducer(state: GameModuleState, action: GameModuleAction): GameModuleState {

    switch (action.type) {
        case GAME_MODULE_ACTION.FETCH:
            return {
                ...state,
                isLoaded: false,
                isLoading: true,
            }

        case GAME_MODULE_ACTION.FETCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                ...action.payload
            }

        default:
            return state;
    }

}
