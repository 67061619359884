import React, { useEffect, useRef, useCallback } from "react";
import Vimeo from '@vimeo/player';

import './style.scss';

type Props = {
    videoId: number,
    autoplay?: boolean,
    loop?: boolean,
    onTimeUpdate?: (time: number) => void,
    onEnded?: () => void
}

const VimeoPlayer: React.FunctionComponent<Props> = ({
    videoId,
    autoplay = true,
    loop = false,
    onTimeUpdate,
    onEnded
}) => {

    // const embed = `<iframe src="https://player.vimeo.com/video/428955700" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`
    // const player

    const player = useRef<Vimeo>();

    const savePosition = useCallback((position: number) => {
        let data: {[key: string]: number} = {}
        try {
            data = JSON.parse(localStorage.getItem(`jti_video`) || '{}')
        } catch(e) {}

        data[videoId] = position | 0;

        localStorage.setItem(`jti_video`, JSON.stringify(data));
    }, [videoId])

    const getStartPosition = useCallback(() => {
        try {
            return JSON.parse(localStorage.getItem(`jti_video`) || '{}')[videoId];
        } catch (e) {
            return 0
        }
    }, [videoId])

    useEffect(() => {
        if (!player.current && !isNaN(videoId)) {
            player.current = new Vimeo('vimeo-player', {
                id: videoId,
                width: 715,
                portrait: false,
                title: false,
                byline: false,
                autoplay,
                controls: true,
                loop,
                responsive: true,
                muted: false,
            })
        } else {
            player.current?.getVideoId()
                .then(id => {
                    if (id !== videoId) {
                        if (player.current)
                            player.current.loadVideo(videoId);
                    }
                })
        }

        if (player.current) {

            player.current.setVolume(1);

            player.current.on('playing', () => {
            })

            player.current.on('loaded', async (e: any) => {
                const startPosition = getStartPosition();
                const duration = await player.current?.getDuration();

                if (duration && startPosition >= duration) {
                    onEnded && onEnded();
                }

                if (startPosition) {
                    player.current?.setCurrentTime(startPosition);
                }
            })

            player.current.on('pause', () => {
            })

            player.current.on('timeupdate', ({ duration, percent, seconds }) => {
                savePosition(seconds);
                onTimeUpdate && onTimeUpdate(seconds)
            })

            player.current.on('ended', () => {
                onEnded && onEnded()
            })
        }

        return () => {
            player.current?.off('playing');
            player.current?.off('loaded');
            player.current?.off('pause');
            player.current?.off('timeupdate');
            player.current?.off('ended');
        }

    }, [videoId, autoplay, onTimeUpdate, onEnded, loop, savePosition, getStartPosition])

    return (
        <div className='vimeo-player'>
            <div className='vimeo-player-inner'>
                <div id="vimeo-player" style={{ position: "relative", width: '100%', maxWidth: '100%' }}>
                </div>
            </div>
        </div>
    )
}

export default VimeoPlayer;
