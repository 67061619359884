import useGame from "../../modules/game/hooks/use-game";
import React, { useEffect, useState } from "react";
import ReactHtmlParser from 'react-html-parser'; 

//@ts-ignore
import * as sanitizeHtml from 'sanitize-html';
import useUserCompletedTasks from "../../modules/user/hooks/use-user-completed-tasks";
type PopupProps = {
    task: Task,
    onClick: React.MouseEventHandler
}

export default function Popup({ task, onClick }: PopupProps) {

    const [showAwards, setAwardsState] = useState<Boolean>(false);

    const { game } = useGame();

    const ending: string[] = (task ? (typeof task.ending === 'string' ? [task.ending] : task.ending) : []) || [''];

    const comletedTasks = useUserCompletedTasks();

    useEffect(() => {
        let arr1 = [0];
        if (task.id > 110 && task.id < 120) {
            arr1 = [111, 112, 113, 114, 115];
        } else if (task.id > 120 && task.id < 130) {
            arr1 = [121, 122, 123, 124, 125];
        } else if (task.id > 130 && task.id < 140) {
            arr1 = [131, 132, 133, 134, 135];
        } else if (task.id > 140 && task.id < 150) {
            arr1 = [141, 142, 143, 144, 145];
        } else if (task.id > 150 && task.id < 160) {
            arr1 = [151, 152, 153, 154, 155];
        }
        
        setAwardsState(!arr1.some(item => comletedTasks.includes(item)));
        
    }, [task, setAwardsState, comletedTasks])

    useEffect(() => {
        window.document.body.style.overflow = 'hidden';

        return () => {
            window.document.body.style.overflow = 'auto';
        }
    })



    return (
        <div className="popup">
            <div className="container">
                <div className="inner">
                    <h3>Gratulacje!</h3>
                    {
                        showAwards && 
                        <p className="info-text">Za rozwiązanie zadania zdobywasz następujące nagrody:</p>       
                    }
                    { 
                        showAwards &&
                            <div className="img-list">
                                <div className="awards">
                                    {
                                        task.awards.map(
                                            a => game.awards.find(
                                                s => s.id === a)
                                        ).map((s, index) => {
                                            return (<div key={index}><img src={process.env.PUBLIC_URL + s?.iconSrc || s?.src} alt={s?.id} /></div>)
                                        })
                                    }
                                </div>
                            </div>
                    }
                    { 
                        ending.map((d, index) => {                        
                            return (
                                <p key={index} className="ending-text">
                                    { ReactHtmlParser (d) }
                                </p>
                            )
                        })
                    }

                    <nav>                    
                        <button className="button-image button-room" onClick={onClick}>
                            <span>Wróć do pokoju</span>
                        </button>
                    </nav>
                </div>

            </div>

        </div>
    )
}
