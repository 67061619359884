import { useGameModuleDispatch, useGameModuleState, GAME_MODULE_ACTION } from "..";
import makeRequest from "../../../utils/network/make-request";
import { useCallback, useEffect } from "react";

function getGame() {
    return makeRequest('/online/data.json').then(response => {
        return response.data
    });
}
export default function useGame() {
    const dispatch = useGameModuleDispatch();
    const game = useGameModuleState();

    const loadData = useCallback(getGame, [getGame])

    useEffect(() => {
        if (!game.isLoaded && !game.isLoading) {
            dispatch({
                type: GAME_MODULE_ACTION.FETCH
            })
            loadData()
                .then(data => {
                    dispatch({
                        type: GAME_MODULE_ACTION.FETCH_SUCCESS,
                        payload: {
                            ...data
                        }
                    })
                })
        }
    })

    return {
        game,
        isLoading: game.isLoading,
        isLoaded: game.isLoaded
    }
}
