import React, { useCallback, useState } from "react";
import './style.scss';

type Props = {
    onComplete?: () => void
}

const TaskGame152: React.FunctionComponent<Props> = () => {

    const [isOpen, setOpenState] = useState(false);
    const [text, setTextState] = useState('');

    const openChest = useCallback(() => {
        setOpenState(true);
    }, [setOpenState,]);

    const closeChest = useCallback(() => {
        setOpenState(false);
    }, [setOpenState,]);

    const updateText = useCallback((event) => {
        if (event.target !== undefined && event.target.value !== undefined) {        
            setTextState(event.target.value);
        } 
    },  [setTextState]);

    return (
        <div className="task-game-152">
            {
                !isOpen && <div className="skrzynia" onClick={openChest}>
                    <img src={process.env.PUBLIC_URL + '/tasks/task-152/skrzynia.png'}/>  
                </div>
            }
            {
                isOpen && <div className="skrzynia-open">
                    <button className="closeChest" onClick={closeChest}>
                        <img src={process.env.PUBLIC_URL + '/tasks/task-152/close.png'} />
                    </button>
                    <img src={process.env.PUBLIC_URL + '/tasks/task-152/skrzynia-open.png'}/>   
                    <textarea placeholder="wpisz tutaj" onChange={updateText} defaultValue={text}></textarea>
                </div>
            }
        </div>
    )
}

export default TaskGame152;
