import React, { useState, useCallback } from "react";

type Props = {
    id: string,
    onDrop: (pickupData: string, dropId: string) => void;
}

const DropZone: React.FunctionComponent<Props> = ({ id, children, onDrop }) => {
    const [isOver, setIsOver] = useState(false);

    const onDragOverDropZone = useCallback((e: React.DragEvent) => {
        e.preventDefault();
        setIsOver(true);
    }, [setIsOver])

    const onDragLeave = useCallback((e: React.DragEvent) => {
        e.preventDefault();
        setIsOver(false);
    }, [setIsOver])

    const onDropHandler = useCallback((e: React.DragEvent) => {
        e.preventDefault();
        setIsOver(false);

        try {
            const pickupData = e.dataTransfer.getData('text');
            onDrop(pickupData, id);
        } catch (e) { }
    }, [setIsOver, id, onDrop])

    return (
        <div className={`drop-zone ${isOver ? 'is-over' : ''}`}
            onDragOver={onDragOverDropZone}
            onDragLeave={onDragLeave}
            onDrop={onDropHandler}
        >
            {children}
        </div>
    )
}

export default DropZone;
