import React, { useCallback, useState } from "react";
import './style.scss';

type Props = {
    onComplete?: () => void
}

const TaskGame124: React.FunctionComponent<Props> = () => {

    return (
        <div className="task-game-124">
            <div>
                <div className="item">    
                    <div className="envelop"></div>
                    <p>PILNE! Promocja w drogerii na kremy na noc!!!</p>                                    
                    <label className="checkbox-container">
                        <input type="checkbox" />&nbsp;
                        <span className="checkmark checkmark2"></span>
                    </label>
                </div>
                <div className="item">    
                    <div className="envelop"></div>
                    <p>Praca domowa dla klasy 7b na jutro.</p>                                    
                    <label className="checkbox-container">
                        <input type="checkbox" />&nbsp;
                        <span className="checkmark checkmark2"></span>
                    </label>
                </div>
                <div className="item">    
                    <div className="envelop"></div>
                    <p>Od dyrekcji szkoły - do odczytania</p>                                    
                    <label className="checkbox-container">
                        <input type="checkbox" />&nbsp;
                        <span className="checkmark checkmark2"></span>
                    </label>
                </div>     
                <div className="item">    
                    <div className="envelop"></div>
                    <p>Sprwadź już teraz! Gra planszowa w nadzwyczajnej cenie?!</p>                                    
                    <label className="checkbox-container">
                        <input type="checkbox" />&nbsp;
                        <span className="checkmark checkmark2"></span>
                    </label>
                </div>       
            </div>
        </div>
    )
}

export default TaskGame124;
