import React, { useCallback, useEffect, useState } from "react";
import './style.scss';

type Props = {
    onComplete?: () => void
}

type Item = {
    id: string,
    src: string,
    class: string,
    text: string
}

const TaskGame153: React.FunctionComponent<Props> = () => {

    const data : Item[] = [
        {
            id: "1",
            class: 'hide-element',
            src: `${process.env.PUBLIC_URL}/tasks/task-153/wc.png`,
            text: ""
        },
        {
            id: "2",
            class: 'hide-element',
            src: `${process.env.PUBLIC_URL}/tasks/task-153/wc.png`,
            text: ""
        },
        {
            id: "3",
            class: 'hide-element',
            src: `${process.env.PUBLIC_URL}/tasks/task-153/wc.png`,
            text: ""
        },
        {
            id: "4",
            class: 'hide-element',
            src: `${process.env.PUBLIC_URL}/tasks/task-153/nakrycie.png`,
            text: ""
        },
        {
            id: "5",
            class: 'hide-element',
            src: `${process.env.PUBLIC_URL}/tasks/task-153/nakrycie.png`,
            text: ""
        },
        {
            id: "6",
            class: 'hide-element',
            src: `${process.env.PUBLIC_URL}/tasks/task-153/nakrycie.png`,
            text: ""
        },
        {
            id: "7",
            class: 'hide-element',
            src: `${process.env.PUBLIC_URL}/tasks/task-153/nakrycie.png`,
            text: ""
        },
        {
            id: "8",
            class: 'hide-element',
            src: `${process.env.PUBLIC_URL}/tasks/task-153/sofa.png`,
            text: ""
        },
        {
            id: "9",
            class: 'hide-element',
            src: `${process.env.PUBLIC_URL}/tasks/task-153/sofa.png`,
            text: ""
        },
        {
            id: "10",
            class: 'hide-element',
            src: `${process.env.PUBLIC_URL}/tasks/task-153/talerz.png`,
            text: ""
        },
        {
            id: "11",
            class: 'hide-element',
            src: `${process.env.PUBLIC_URL}/tasks/task-153/hantle.png`,
            text: ""
        }              

    ]

    const [isOpen, setOpenState] = useState(false);
    // const [text, setTextState] = useState('');

    // const openChest = useCallback(() => {
    //     setOpenState(true);
    // }, [setOpenState,]);

    const closeChest = useCallback(() => {
        setOpenState(false);
    }, [setOpenState,]);

    const updateText = useCallback((event) => {
 
        console.log(event);
        // if (event.target !== undefined && event.target.value !== undefined) {        
            // setTextState(event.target.value);
        // } 
    },  []);      

    const openText = useCallback((event) => {
        let index = data.findIndex(i => i.id === event);
        if (index) {
            data[index].class = 'show-element';
        }
        // if (item1) {
        // item1.isOpen = true;
        // console.log(item1);
        // }
        // console.log(item1);
        console.log(data)
    }, [data]);

    const hideText = useCallback((event) => {
        console.log(event);
        // console.log(event);
    }, []);

    const getClass = useCallback((id) => {        
        let item = data.find(i => i.id === id);
        if (item) {
            return item.class;
        }

        // }
        // console.log(id);
        // return 'show-element';
    }, [data]);

    return (
        <div className="task-game-153">            
                <div className="rzut">
                    <img className="mapa" src={process.env.PUBLIC_URL + '/tasks/task-153/rzut.png'}/>  
                    <ItemElement item={data[0]}></ItemElement>
                    <ItemElement item={data[1]}></ItemElement>
                    <ItemElement item={data[2]}></ItemElement>
                    <ItemElement item={data[3]}></ItemElement>
                    <ItemElement item={data[4]}></ItemElement>
                    <ItemElement item={data[5]}></ItemElement>
                    <ItemElement item={data[6]}></ItemElement>
                    <ItemElement item={data[7]}></ItemElement>
                    <ItemElement item={data[8]}></ItemElement>
                    <ItemElement item={data[9]}></ItemElement>
                    <ItemElement item={data[10]}></ItemElement>
                </div>                
        </div>
    )
}

const ItemElement: React.FunctionComponent<{ item: Item }> = ({ item }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [text, setText] = useState("");

    const openText = useCallback(() => {
        setIsOpen(true);
    }, [setIsOpen, isOpen]);

    const hideText = useCallback(() => {        
        setIsOpen(false);
    }, [setIsOpen, isOpen]);

    const updateText = useCallback((event) => {
        if (event.target !== undefined && event.target.value !== undefined) {        
            setText(event.target.value);
        } 
    }, [setText]);

    useEffect(() => {
        console.log(item);
        setText(item.text);
        
    }, [item, setText])

    return (
        <>
        <div key={item.id} className={"item item"+item.id}>
            <img src={item.src} onClick={openText}/>
        </div>     
        {
            isOpen && <>
                <div className="textWrapper">
                    <textarea placeholder="wpisz tutaj" onChange={updateText} defaultValue={text}></textarea>
                    <button className="closeChest" onClick={hideText}>
                        <img src={process.env.PUBLIC_URL + '/tasks/task-152/close.png'} />
                    </button>
                </div>
            </>
        }      
        </>
    )
}

export default TaskGame153;
