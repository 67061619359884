import { useUserModuleDispatch, USER_MODULE_ACTION } from "..";
import { useCallback } from "react";

export default function useSetUserEmblem() {
    const dispatch = useUserModuleDispatch();

    const setUserName = useCallback((emblem: string) => {
        dispatch({
            type: USER_MODULE_ACTION.SET_EMBLEM,
            payload: {
                emblem
            }
        })
    }, [dispatch])

    return setUserName
}
