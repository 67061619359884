import React, { useEffect } from "react";
import { BACKGROUND_TYPE, useBackgroundState, useBackgroundDispacher } from ".";



export type PatternBackgroundState = {
    type: BACKGROUND_TYPE.PATTERN,
    src: string
}

const PatternBackground: React.FunctionComponent = ({ children }) => {

    const {src} = useBackgroundState() as PatternBackgroundState;

    useEffect(() => {
        window.document.body.style.backgroundImage = `url('${process.env.PUBLIC_URL + src}')`;
        window.document.body.style.backgroundColor = `#fff`;
        return;
    }, [src])

    return (<>{children}</>);
}

export function useSetPatternBackground() {
    const dispatch = useBackgroundDispacher();
    return (src: string) => {
        dispatch({
            type: 'set',
            newState: {
                type: BACKGROUND_TYPE.PATTERN,
                src
            }
        })
    }
}

export default PatternBackground;
