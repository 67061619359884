import { forwardRef, useRef, useEffect, useCallback, useState, useMemo } from "react";
import React from "react";
import useUser from "../../modules/user/hooks/use-user";
import createApp from "./app";
import useUserAwards from "../../modules/user/hooks/use-user-awards";
import useGame from "../../modules/game/hooks/use-game";

type Props = {

}

type API = {
    getImage: () => Promise<string>
}

const Dyplom = forwardRef<API, Props>((_, ref) => {
    const container = useRef<HTMLDivElement>(null);

    const { name } = useUser();

    const [gameAPI, setGameAPI] = useState<{
        getImage: () => Promise<string>
    }>();

    const userAwards = useUserAwards();
    const {game, isLoaded} = useGame();

    const awards = game.awards;

    const awardsData = useMemo(() => awards.map(a => {
        return {
            ...a,
            active: Boolean(userAwards.find(ua => ua === a.id)),
        }
    }), [awards, userAwards])

    const onDownloadClick = useCallback((e: React.MouseEvent) => {
        e.preventDefault();

        if (gameAPI) {
            gameAPI.getImage().then((image: string) => {
                if (navigator.msSaveBlob) {
                    navigator.msSaveBlob(b64toBlob(image.replace(/^data:image\/(png|jpeg|jpg);base64,/, '')), 'dyplom.png');
                } else {
                    var a = document.createElement('a');
                    a.href = image;
                    a.style.display = 'none';
                    a.setAttribute('download', 'dyplom.png');
                    document.body.appendChild(a);
                    a.click();
                }
            })
        }
    }, [gameAPI])

    useEffect(() => {
        if (container.current && isLoaded) {
            const {getSceenBlob, uninstall} = createApp({
                container: container.current,
                userName: name || '',
                awardsData
            })

            setGameAPI({getImage: getSceenBlob});

            const c = container.current;

            return () => {
                uninstall();
                if (c) {
                    c.innerHTML = '';
                }
            }
        }


    }, [setGameAPI,awardsData, isLoaded, container, name]);

    return (
        <>
            <div ref={container} className="dyplom"></div>
            <button className="button-image button-download" onClick={onDownloadClick}>
                <span>Pobierz</span>
            </button>
        </>
    )
});

export default Dyplom;

const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}
