import useGame from "./use-game";

export default function useTask(id: number) {

    const {game, isLoading} = useGame()

    return {
        task: game.tasks.find(t => t.id === id),
        isLoading
    }

}
