import React, { useCallback, useState } from "react";
import './style.scss';

type Props = {
    onComplete?: () => void
}

const TaskGame151: React.FunctionComponent<Props> = () => {

    return (
        <div className="task-game-151">
            <div>
                <img src={process.env.PUBLIC_URL + '/tasks/task-151/dziennik.png'}/> 
                <textarea className="textarea-1"></textarea>
                <textarea className="textarea-2">asd</textarea>
            </div>
        </div>
    )
}

export default TaskGame151;
