const THEME = {
    color1: '#fff8e6',
    color2: '#f29100',
    color3: '#cd1719',
    layoutWidth: '1366px',
    layoutTopMargin: '220px',
    layoutBottomMargin: '115px',

    font: {
        amita: `'Amita', cursive`,
        roboto: `'Roboto', sans-serif`
    }
}

export default THEME;
