import { useUserModuleState } from "..";

export default function useUser() {
    const { name, completedTasks, emblem } = useUserModuleState();

    return {
        name,
        emblem,
        completedTasks
    }
}
