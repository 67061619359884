import React, { useCallback, useState } from "react";
import './style.scss';

type Props = {
    onComplete?: () => void
}

const TaskGame143: React.FunctionComponent<Props> = () => {

    return (
        <div className="task-game-143">
            <div>
                <label>Najlepiej spędzony wolny czas z rodziną to...</label>
                <input type="text" placeholder="wpisz tutaj"></input>
            </div>
            <div>
                <label>Najlepiej spędzony wolny czas z rodziną to...</label>
                <input type="text" placeholder="wpisz tutaj"></input>
            </div>
            <div>
                <label>Najlepiej spędzony wolny czas z rodziną to...</label>
                <input type="text" placeholder="wpisz tutaj"></input>
            </div>
        </div>
    )
}

export default TaskGame143;
