import * as PIXI from 'pixi.js';
import { AppConfig } from './app';

const grayscale = new PIXI.filters.ColorMatrixFilter();
grayscale.greyscale(0.5, false);

export default class Dyplom extends PIXI.Application {
    public baseWidth: number = 1207;
    public baseHeight: number = 1707;
    private baseRatio: number = this.baseHeight / this.baseWidth;
    private scaleRatio = 1;

    private resources?: Partial<Record<string, PIXI.LoaderResource>>;

    constructor(private config: AppConfig) {
        super({
            width: 2480,
            height: 3508
        });

        window.addEventListener('resize', this.gameResize);

        this.loadTextures().then((resources) => {
            this.resources = resources;
            this.init()
        })
    }

    private init() {
        if (this.resources?.tlo) {
            const tlo = new PIXI.Sprite(this.resources.tlo.texture);
            this.stage.addChild(tlo);
        }

        const name = new PIXI.Text(this.config.userName, {
            fontSize: 80,
            fontFamily: 'Open Sans',
            fill: 'red'            
        });
        this.stage.addChild(name);
        name.anchor.set(.5);
        name.x = this.baseWidth / 2;
        name.y = 1020;

        // const row1 = 520;
        // const row2 = row1 + 150;
        // const row3 = row2 + 150;
        // const row4 = row3 + 150;
        // const scale = .7;

        // if (this.resources?.a) {
        //     const a = new PIXI.Sprite(this.resources.a.texture);
        //     this.stage.addChild(a);
        //     a.anchor.set(.5);
        //     a.scale.set(scale);
        //     a.x = 3/16 * this.baseWidth;
        //     a.y = row1;
        //     if (!this.config.awardsData.find(a => a.id === 'a')?.active) {
        //         a.filters = [grayscale];
        //     }
        // }

        // if (this.resources?.b) {
        //     const a = new PIXI.Sprite(this.resources.b.texture);
        //     this.stage.addChild(a);
        //     a.anchor.set(.5);
        //     a.scale.set(scale);
        //     a.x = 5/16 * this.baseWidth;
        //     a.y = row1;
        //     if (!this.config.awardsData.find(a => a.id === 'b')?.active) {
        //         a.filters = [grayscale];
        //     }
        // }

        // if (this.resources?.c) {
        //     const a = new PIXI.Sprite(this.resources.c.texture);
        //     this.stage.addChild(a);
        //     a.anchor.set(.5);
        //     a.scale.set(scale);
        //     a.x = 7/16 * this.baseWidth;
        //     a.y = row1;
        //     if (!this.config.awardsData.find(a => a.id === 'c')?.active) {
        //         a.filters = [grayscale];
        //     }
        // }

        // if (this.resources?.d) {
        //     const a = new PIXI.Sprite(this.resources.d.texture);
        //     this.stage.addChild(a);
        //     a.anchor.set(.5);
        //     a.scale.set(scale);
        //     a.x = 9/16 * this.baseWidth;
        //     a.y = row1;
        //     if (!this.config.awardsData.find(a => a.id === 'd')?.active) {
        //         a.filters = [grayscale];
        //     }
        // }

        // if (this.resources?.e) {
        //     const a = new PIXI.Sprite(this.resources.e.texture);
        //     this.stage.addChild(a);
        //     a.anchor.set(.5);
        //     a.scale.set(scale);
        //     a.x = 11/16 * this.baseWidth;
        //     a.y = row1;
        //     if (!this.config.awardsData.find(a => a.id === 'e')?.active) {
        //         a.filters = [grayscale];
        //     }
        // }    
    }

    private loadTextures(): Promise < Partial < Record < string, PIXI.LoaderResource >>> {
    return new Promise((resolve) => {

        this.config.awardsData.forEach((a) => {
            this.loader.add(a.id, process.env.PUBLIC_URL + a.iconSrc);
        });

        this.loader.add('tlo', process.env.PUBLIC_URL + '/images/dyplom/dyplom.png')

        this.loader.load((loader, resource) => {
            resolve(resource)
        })

        this.loader.onError.add((...args: any[]) => {
            console.error(...args);
        })
    })
}

    public gameResize = () => {
    const nW = this.config.container.offsetWidth;
    const nH = nW * this.baseRatio;

    this.renderer.resize(nW, nH);

    this.scaleRatio = (nW / this.baseWidth);

    this.stage.scale.set(this.scaleRatio)
}

    public destroy() {
    window.removeEventListener('resize', this.gameResize);
}

    public async getSceenBlob(): Promise < string > {
    const base64 = this.renderer.plugins.extract.base64(this.stage, 'image/png', 1);

    return Promise.resolve(base64);
}
}
