import React, { useRef, useEffect } from "react";
import createGame from "./game";

export default function KoronyGame() {

    const gameContainer = useRef<HTMLDivElement>(null);

    useEffect(() => {

        if (gameContainer.current) {
            const {
                uninstall
            } = createGame({
                container: gameContainer.current
            });

            return () => { uninstall() }
        }
    }, [])

    return (
        <div className="korony-game">
            <div ref={gameContainer}></div>
        </div>
    )
}
