import React, { useEffect } from 'react';
import styled from 'styled-components';
import CenterLolumnLayout from '../../layout/screen';
import './style.scss';
import { Link, useLocation } from 'react-router-dom';
import PageFooter from '../../components/page-footer';
import { getLoginRoute, getMapRoute } from '../../routes';
import { useResetBackground } from '../../layout/background';


const IntroScreen = styled.main``

export default function IntroPage() {

    const resetBackground = useResetBackground();
    useEffect(() => {
        resetBackground();
    }, [resetBackground])

    useEffect(() => {
        document.body.classList.add('rules-page');

        return () => {
            document.body.classList.remove('rules-page');
        }
    })

    const { state } = useLocation<{ fromMap: boolean }>();

    const showGoToMap = state ? state.fromMap : false;
    const showGoToLogin = !showGoToMap;

    return (
        <CenterLolumnLayout footer={PageFooter()}>
            <IntroScreen className='rules-screen column'>
                <h1>Zasady gry</h1>

                <ol>
                    <li>Na kolejnej stronie wpiszcie swoje imię/pseudonim lub nazwę waszej drużyny.</li>
                    <li>Przed wami wyzwanie, czyli zadania do wykonania – czasami do ich realizacji nie będzie wam potrzebny komputer, a korzystanie z różnych materiałów, a także – co bardzo ważne – wizyta w waszej lokalnej czy szkolnej bibliotece. </li>
                    <li>Możecie wybrać każde z 30 zadań dostępnych na mapie. </li>
                    <li>Zadania są oznaczone kolorami w naszej wirtualnej bibliotece. Aby rozpocząć zadanie, kliknijcie w zakładkę. </li>
                    <li>W niektórych zadaniach napotkacie karty pracy. Możecie je pobrać na swój komputer, klikając w przycisk “Pobierz”, a następnie wydrukować. </li>
                    <li><span className="sub-list-intro">Kartę pracy można też odrysować, pozwoli to na oszczędność tuszu i papieru. W tym celu:</span>
                        <ol>
                            <li>kliknijcie w widoczną kartę pracy; </li>
                            <li>taśmą klejącą przyklejcie kartkę do ekranu;</li>
                            <li>ołówkiem odrysujcie prześwitujący obrazek;</li>
                            <li>wykonajcie dalszą część zadania.</li>
                        </ol>
                    </li>
                    <li>Ukończenie zadania musicie potwierdzić, klikając w przycisk „Zadanie wykonane”.</li>
                    <li>Za większość zadań możecie zdobyć pieczątkę z ekslibrisem Nocy Bibliotek 2020.</li>
                    <li>Już za wykonanie minimum 5 zadań możecie otrzymać dyplom gry, klikając w przycisk: „Zakończ”.</li>
                    <li>Aby wrócić do instrukcji, kliknijcie na mapie w napis: “Instrukcja”.</li>
                    <li>Powodzenia! </li>
                </ol>

                {showGoToLogin && <Link to={getLoginRoute()} className="button-big"><span>Dalej</span></Link>}
                {showGoToMap && <Link to={getMapRoute()} className="button-big"><span>Rozwiń mapę</span></Link>}
            </IntroScreen>
        </CenterLolumnLayout >
    )
}
