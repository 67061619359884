import React from "react";
import './style.scss';

type Props = {
    footer?: JSX.Element
}

const CenterLolumnLayout: React.FunctionComponent<Props> = ({ children, footer }) => {
    return (
        <div className="outer-screen">
            <div className='screen'>
                <div className="screen-wrapper">
                    {children}
                </div>
            </div>
            {footer && <div className="footer">
                {footer}
            </div>
            }
        </div>
    )
}

export default CenterLolumnLayout;
