import React from "react";
import './style.scss';

type Props = {
    onComplete?: () => void
}

const TaskGame112: React.FunctionComponent<Props> = () => {
    return (
        <div className="task-game-112">
            <label>
                <textarea placeholder="wpisz tutaj"></textarea>
            </label>
        </div>
    )
}

export default TaskGame112;
