import { useCallback } from "react";
import { useUserModuleDispatch, USER_MODULE_ACTION } from "..";

export default function useSetUserCompleteTask() {
    const dispatch = useUserModuleDispatch();

    const setUserCompleteTask = useCallback((id: number) => {
        dispatch({
            type: USER_MODULE_ACTION.COMPLETE_TASK,
            payload: {
                id
            }
        })
    }, [dispatch])

    return setUserCompleteTask;
}
