import React, { useEffect } from "react";
import { BACKGROUND_TYPE, useBackgroundState, useBackgroundDispacher } from ".";



export type FullImageBackgroundState = {
    type: BACKGROUND_TYPE.FULL_IMAGE,
    src: string
}

const FullImageBodyBackgound: React.FunctionComponent = ({ children }) => {

    const {src} = useBackgroundState() as FullImageBackgroundState;

    useEffect(() => {
        window.document.body.style.background = `#fff url('${src}') no-repeat 50% 50%`;
        window.document.body.style.backgroundSize = 'cover';
        return;
    }, [src])

    return (<>{children}</>);
}

export function useSetFullImageColorBackground() {
    const dispatch = useBackgroundDispacher();
    return (src: string) => {
        dispatch({
            type: 'set',
            newState: {
                type: BACKGROUND_TYPE.FULL_IMAGE,
                src
            }
        })
    }
}

export default FullImageBodyBackgound;
