import React, { useCallback, useState, useEffect, useRef } from "react";

const EMPTY_IMAGE = new Image();
EMPTY_IMAGE.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+M9QDwADhgGAWjR9awAAAABJRU5ErkJggg==';

type Props = {
    data: string
}

const PickUp: React.FunctionComponent<Props> = ({ data, children }) => {
    const [dragPosition, setDragPosition] = useState([0, 0]);
    const previewRef = useRef<HTMLDivElement>(null);
    const [isDragging, setDrag] = useState(false);

    const onDragStart = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.dataTransfer.setData('text', data || '')
        e.dataTransfer.dropEffect = 'move';

        e.dataTransfer.setDragImage(EMPTY_IMAGE, 0, 0);
        const rect = e.currentTarget.getBoundingClientRect();

        setDragPosition([rect.width / 2, rect.height / 2])

        if (previewRef.current) {
            previewRef.current.style.width = `${rect.width}px`;
        }

        setDrag(true);
    }, [data, setDrag])

    const onDragEnd = useCallback((e: React.DragEvent) => {
        e.preventDefault();
        setDrag(false);
    }, [setDrag])

    const onDragOverDocument = useCallback((e: DragEvent) => {
        if (previewRef.current) {
            previewRef.current.style.left = `${e.pageX - dragPosition[0] - window.scrollX}px`;
            previewRef.current.style.top = `${e.pageY - dragPosition[1] - window.scrollY}px`;
        }
    }, [dragPosition])

    useEffect(() => {
        document.addEventListener('dragover', onDragOverDocument);

        return () => {
            document.removeEventListener('dragover', onDragOverDocument);
        }
    }, [onDragOverDocument]);

    return (
        <>
            <div className="pick-up"
                // id={pickup.id}
                draggable={true}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                // style={{
                //     display: !isDragging ? 'block' : 'none'
                // }}
            >
                {children}
            </div>
            <div
                className="preview" ref={previewRef} style={{
                    display: isDragging ? 'block' : 'none',
                    position: 'fixed',
                    width: 0,
                    pointerEvents: 'none',
                    zIndex: 1000
                }}
            >{children}</div>
        </>
    )
}

export default PickUp;
