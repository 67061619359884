import React from "react"
import MemoryGame from "../../components/memory-game"
import DocumentPrezenter from "../../components/document-prezenter"
import AudioPlayer from "../../components/audio-player"
import Puzzle from "../../components/puzzle"
import YouTubeEmbed from "react-youtube-embed"
import DnDTemplate from "../../components/drag-drop-template"
import TaskGame from "../../components/task-game"
import KoronyGame from "../../components/korony-game"

//@ts-ignore
import * as sanitizeHtml from 'sanitize-html';
import VimeoPlayer from "../../components/vimeo-player"

type TEProps = {
    elements: TaskElement[]
}

const TaskElements: React.FunctionComponent<TEProps> = ({ elements }) => {
    return (
        <>
            {elements.map((e, index) => {
                let el = <></>;
                switch (e.type) {

                    case 'html':
                        el = (
                            <section>
                                {e.params.header && <h3>{e.params.header}</h3>}
                                <div dangerouslySetInnerHTML={
                                    {
                                        __html: sanitizeHtml(e.params.content, {
                                            allowedClasses: {
                                                p: ['quote'],
                                                ol: ['type-2']
                                            }
                                        })
                                    }
                                }>
                                </div>
                            </section>
                        )
                        break;

                    case 'image':
                        el = (
                            <section className="image">
                                <img src={process.env.PUBLIC_URL + e.params.src} alt={e.params.alt} width={e.params?.width} />
                            </section>
                        )
                        break;



                    case 'task-instruction':
                        el = (
                            <section className="task-instruction">
                                <p>
                                    <strong>Zadanie:</strong> <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(e.params.content) }}>
                                    </span>
                                </p>
                            </section>
                        )
                        break;

                    case 'memo-game':
                        el = (
                            <MemoryGame></MemoryGame>
                        );
                        break;

                    case 'document':
                        el = (
                            <DocumentPrezenter
                                document={e.params.document}
                                thumbnails={e.params.thumbnails}
                                altLabel={e.params.altLabel}
                                format={e.params.format}
                            />
                        );
                        break;

                    case 'audio':
                        el = (
                            <AudioPlayer
                                src={e.params.src}
                            />
                        );
                        break;

                    case 'puzzle':
                        el = (
                            <Puzzle code={e.params.code} />
                        );
                        break;

                    case 'youtube':
                        el = (
                            <YouTubeEmbed
                                id={e.params.id}
                            ></YouTubeEmbed>
                        );
                        break;

                    case 'vimeo':
                        el = (
                            <VimeoPlayer videoId={e.params.id} />
                        )
                        break;

                    case 'game':
                        el = (
                            <TaskGame id={e.params.id}></TaskGame>
                        )
                        break;

                    case 'korony-game':
                        el = (
                            <KoronyGame />
                        );
                        break;                        

                    /* DEV */
                    case 'dnd-template':
                        el = (
                            <DnDTemplate></DnDTemplate>
                        )
                        break;

                    default:
                        console.error('Unknow element type:', e.type);
                        el = (<></>);
                }

                return <React.Fragment key={index}>{el}</React.Fragment>
            })}
        </>
    )
}

export default TaskElements;
