import CenterLolumnLayout from "../../layout/screen";
import React, { useEffect } from "react";
import PageFooter from '../../components/page-footer';
import { getLoginRoute, getMapRoute } from '../../routes';
import './style.scss';
import { Link, useLocation } from 'react-router-dom';
import { useSetPatternBackground } from "../../layout/background";
import VimeoPlayer from "../../components/vimeo-player";

const ManualPage: React.FunctionComponent = () => {
    const { state } = useLocation<{ fromMap: boolean }>();    
    const showGoToMap = state ? state.fromMap : false;
    const showGoToLogin = !showGoToMap;
    const setBackground = useSetPatternBackground();

    useEffect(() => {
        setBackground(`/images/backgrounds/pattern_1.png`);
    })            
    
    return (
        <CenterLolumnLayout footer={PageFooter()}>
            <main className='column manual-screen'>
                <h1>Instrukcja</h1>
                <br />
                <VimeoPlayer videoId={512840679} />                
            </main>
            <nav className="home-nav">
                <div>
                    {showGoToLogin && <Link to={getLoginRoute()} 
                        className='button-image button-next'>
                        <span>Dalej</span>
                    </Link>}
                    {showGoToMap && <Link to={getMapRoute()} 
                        className='button-image button-room'>
                        <span>Dalej</span>
                    </Link>}
                </div>
            </nav>            
        </CenterLolumnLayout>
    )
}

export default ManualPage;
