import React, { useEffect, useRef, useCallback, useState } from "react";
import './style.scss';
import { getTaskRoute, getManualRoute, getEndRoute } from "../../routes";
import { Link, useHistory } from "react-router-dom";

import * as Bowser from "bowser";
import useUserCompletedTasks from "../../modules/user/hooks/use-user-completed-tasks";
import useGame from "../../modules/game/hooks/use-game";
import useUserAwards from "../../modules/user/hooks/use-user-awards";
const browser = Bowser.getParser(window.navigator.userAgent);


const SWITCH_RATIO = 1.2;

export default function MapV2() {

    const [lClass, setLayoutClass] = useState('');
    const history = useHistory();

    const mainContainer = useRef<HTMLDivElement>(null);
    const mapContainer = useRef<HTMLDivElement>(null);

    const {game} = useGame()
    const tasks = game.tasks;

    const resize = useCallback(() => {
        const wW = window.innerWidth;
        const wH = window.innerHeight;

        const wRatio = wW / wH;

        if (mapContainer.current && mainContainer.current) {

            // const isMobile = browser.is('mobile');
            // const isColumn = wRatio >= SWITCH_RATIO;

            // if (isColumn && !isMobile) {
            //     setLayoutClass(`map-column desktop`);
            //     mapContainer.current.style.zoom = '' + window.devicePixelRatio;
            //     mapContainer.current.style.height = `auto`
            //     return;
            // }

            // if (isColumn && isMobile) {
            //     setLayoutClass(`map-column mobile`);
            //     return;
            // }

            // if (!isColumn && !isMobile) {
            //     setLayoutClass(`map-row desktop`);
            //     mapContainer.current.style.zoom = '' + window.devicePixelRatio;
            //     mapContainer.current.style.height = `${100 * window.devicePixelRatio}%`
            //     return;
            // }

            // if (!isColumn && isMobile) {
            //     setLayoutClass(`map-row mobile`);
            // }

            setLayoutClass(`map-column desktop`);
        }

    }, [])

    useEffect(() => {
        setTimeout(resize)
        // setTimeout(resize, 10000)
        // resize();

        window.addEventListener('resize', resize);

        return () => window.removeEventListener('resize', resize);
    })

    const completedTasks = useUserCompletedTasks();

    const getTaskState = useCallback((id: number) => {
        if (completedTasks.find(t => t === id)) {
            return 'completed'
        }

        return 'active';
    }, [completedTasks])

    const toRulesClicked = useCallback((e: React.MouseEvent) => {
        e.preventDefault()
        history.push(getManualRoute(), {
            fromMap: true
        })
    }, [history])

    const [showEndLink, setShowEndLinkState] = useState<Boolean>(false);
    const userAwards = useUserAwards();

    useEffect(() => {
        let awards = userAwards.filter((x, i, a) => a.indexOf(x) == i)
        setShowEndLinkState(awards.length >= 5);
    }, [userAwards, setShowEndLinkState])

    useEffect(() => {
        tasks.forEach(t => {
            ['', '-c', '-h'].forEach(surfix => {
                const i = new Image();
                i.src = `${process.env.PUBLIC_URL}/images/mapa/tasks/t-${t.id}${surfix}.png`;
                // document.body.appendChild(i);
            })
        })
    }, [tasks])

    return (
        <>
            <main className={`map-o ${lClass}`} ref={mapContainer}>
                <div className="map-c" ref={mainContainer}>
                    <div className="map-c2">
                        <div className="map-c3">
                            {
                                tasks.map((t, index) => {
                                    return (
                                        <Link key={index} to={getTaskRoute(t.id)} className={`task t-${t.id} ${getTaskState(t.id)}`}></Link>
                                    )
                                })
                            }
                        </div>

                        <nav>
                            {showEndLink && <Link to={getEndRoute()} className="button-image button-end"><span>Zakończ</span></Link>}
                            <Link to={getManualRoute()} 
                                onClick={toRulesClicked}
                                className='button-image button-manual'>
                                <span>Instrukcja</span>
                            </Link>
                        </nav>
                    </div>
                </div>
            </main>
        </>
    )
}
