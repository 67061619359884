import useGame from "../../game/hooks/use-game";
import useUser from "./use-user";
import { useMemo } from "react";

export default function useUserAwards() {
    const {game} = useGame();
    const {completedTasks} = useUser();

    const userAwards = useMemo(() => game.tasks.reduce<string[]>((prev, next) => {
        if (completedTasks.indexOf(next.id) >= 0) {
            return [
                ...prev,
                ...next.awards
            ];
        } else {
            return prev;
        }
    }, []), [game, completedTasks]);

    return userAwards || [];
}
