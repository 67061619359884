import React, { useEffect } from "react";
import CenterLolumnLayout from "../../layout/screen";
import './style.scss'
import PageFooter from "../../components/page-footer";
import { Link } from "react-router-dom";
import { getMapRoute } from "../../routes";
import Dyplom from "../../components/dyplom";
import { useResetBackground } from "../../layout/background";

export default function EndPage() {

    const resetBackground = useResetBackground();
    useEffect(() => {
        resetBackground();
    }, [resetBackground])

    return (
        <CenterLolumnLayout footer={PageFooter()}>

            <div className="end-page">
                <Dyplom></Dyplom>
                <nav>
                    <div className="buttons">
                        <Link className="button-image button-room" to={getMapRoute()}>
                            <span>wróć do pokoju</span>
                        </Link>
                    </div>
                </nav>
            </div>

        </CenterLolumnLayout >
    )
}
