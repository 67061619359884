import React, { useCallback, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import useTask from '../../modules/game/hooks/use-task';
import CenterLolumnLayout from '../../layout/screen';
import { useResetBackground, useSetPatternBackground } from '../../layout/background';
import PageFooter from '../../components/page-footer';
import './style.scss';
import { getMapRoute } from '../../routes';

import { Link } from 'react-router-dom';
import DefaultTask from './default-task';

type Props = {
    id?: string
}

const TaskPage: React.FunctionComponent<Props> = ({ id }) => {
    const { id: uri_id } = useParams();
    const { task, isLoading } = useTask(parseInt(id || uri_id));
    const history = useHistory();


    const resetBackground = useResetBackground();
    const setBackground = useSetPatternBackground();
    useEffect(() => {
        resetBackground();
        if (task) {
            if (task.id > 110 && task.id < 120) {
                setBackground(`/images/backgrounds/pattern_11.png`);
            } else if (task.id > 120 && task.id < 130) {
                setBackground(`/images/backgrounds/pattern_12.png`);
            } else if (task.id > 130 && task.id < 140) {
                setBackground(`/images/backgrounds/pattern_13.png`);
            } else if (task.id > 140 && task.id < 150) {
                setBackground(`/images/backgrounds/pattern_14.png`);
            } else if (task.id > 150 && task.id < 160) {
                setBackground(`/images/backgrounds/pattern_15.png`);
            }            
        }    
    }, [resetBackground, task])

    const onCompleteTask = useCallback(() => {
        if (task) {
            history.push(getMapRoute(), {
                task
            })
        }
    }, [task, history])

    const onCompleteTaskClicked = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        onCompleteTask()
    }, [onCompleteTask])

    if (!task && !isLoading) {
        return (<p>Coś poszło nie tak</p>)
    }


    return (
        <CenterLolumnLayout footer={PageFooter()}>

            <Link to={getMapRoute()} 
                className='button-image button-room to-map'>
                <span>Wróć do pokoju</span>
            </Link>

            {(() => {
                switch (task?.id) {
                    default:
                        return (
                            <DefaultTask
                                task={task as Task}
                            />
                        )
                }
            })()}

            <div className="button-complete">
                <a href={getMapRoute()} onClick={onCompleteTaskClicked} className="button-image button-complete">
                    <span>Zakończ zadanie</span>
                </a>
            </div>

        </CenterLolumnLayout>
    )
}

export default TaskPage;
