import { useMemo } from "react"
import React from "react"
import TaskGame112 from "./task-game-112"
import TaskGame113 from "./task-game-113"
import TaskGame114 from "./task-game-114"
import TaskGame115 from "./task-game-115"
import TaskGame121 from "./task-game-121"
import TaskGame122 from "./task-game-122"
import TaskGame123 from "./task-game-123"
import TaskGame124 from "./task-game-124"
import TaskGame131 from "./task-game-131"
import TaskGame135 from "./task-game-135"
import TaskGame142 from "./task-game-142"
import TaskGame143 from "./task-game-143"
import TaskGame151 from "./task-game-151"
import TaskGame152 from "./task-game-152"
import TaskGame153 from "./task-game-153"

type Props = {
    id: string,
    onComplete?: () => void
}

const TaskGame: React.FunctionComponent<Props> = ({ id, onComplete }) => {

    const game = useMemo(() => {
        switch (id) {

            case 'task-112':
                return <TaskGame112 onComplete={onComplete} />

            case 'task-113':
                return <TaskGame113 onComplete={onComplete} />
    
            case 'task-114':
                return <TaskGame114 onComplete={onComplete} />

            case 'task-115':
                return <TaskGame115 onComplete={onComplete} />                

            case 'task-121':
                return <TaskGame121 onComplete={onComplete} />                    

            case 'task-122':
                return <TaskGame122 onComplete={onComplete} />                

            case 'task-123':
                return <TaskGame123 onComplete={onComplete} />   

            case 'task-124':
                return <TaskGame124 onComplete={onComplete} />   
    
            case 'task-131':
                return <TaskGame131 onComplete={onComplete} />       

            case 'task-135':
                return <TaskGame135 onComplete={onComplete} /> 

            case 'task-142':
                return <TaskGame142 onComplete={onComplete} />                

            case 'task-143':
                return <TaskGame143 onComplete={onComplete} />  

            case 'task-151':
                return <TaskGame151 onComplete={onComplete} /> 

            case 'task-152':
                return <TaskGame152 onComplete={onComplete} /> 

            case 'task-153':
                return <TaskGame153 onComplete={onComplete} /> 

            default:
                return (
                    <p style={{ border: "3px red solid", textAlign: "center" }}>Nieznane ID gry: {id}</p>
                )
        }
    }, [id, onComplete])

    return game;

}

export default TaskGame;
