import React from "react";
import './style.scss';
import PageFooter from "../../components/page-footer";

export default function LandingPage() {
    return (
        <>
            <div className="layout">
                <main className="note">
                    <div className="inner">
                        <h1>Witajcie łowcy i łowczynie przygód!</h1>
                        <p>
                            Już wkrótce będziecie mogli wziąć udział w jedynym w swoim rodzaju turnieju rycerskim z okazji Dnia Dziecka w Muzeum POLIN.<br />
                            Król Maciuś razem z dworzanami szykuje dla Was specjalne zadania. Pokażcie na co Was stać i stańcie się najlepszymi rycerzami i rycerkami Króla Maciusia.
                        </p>
                        <p>
                            W niedzielę 31 maja o godzinie 11.00, otworzymy bramy do Niezwykłej Krainy.<br />
                            Przygotujcie rynsztunek i przybądźcie tu razem z całą rodziną.</p>

                    </div>
                </main>

                <div className="footer-container">
                    <PageFooter />
                </div>

            </div>

        </>
    )
}
