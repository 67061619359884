import React, { useCallback, useState } from "react";
import './style.scss';

type Props = {
    onComplete?: () => void
}

const TaskGame123: React.FunctionComponent<Props> = () => {

    return (
        <div className="task-game-123">
            <div>
                <div className="item">
                    <img src={process.env.PUBLIC_URL + '/tasks/task-123/zoom.png'}/>
                    <label className="checkbox-container">
                        <input type="checkbox" />&nbsp;
                        <span className="checkmark"></span>
                    </label>
                </div>
                <div className="item">
                    <img src={process.env.PUBLIC_URL + '/tasks/task-123/chrome.png'}/>
                    <label className="checkbox-container">
                        <input type="checkbox" />&nbsp;
                        <span className="checkmark"></span>
                    </label>
                </div>
                <div className="item">
                    <img src={process.env.PUBLIC_URL + '/tasks/task-123/teams.png'}/>
                    <label className="checkbox-container">
                        <input type="checkbox" />&nbsp;
                        <span className="checkmark"></span>
                    </label>
                </div>                
                <div className="item">
                    <img src={process.env.PUBLIC_URL + '/tasks/task-123/word.png'}/>
                    <label className="checkbox-container">
                        <input type="checkbox" />&nbsp;
                        <span className="checkmark"></span>
                    </label>
                </div>
                <div className="item">
                    <img src={process.env.PUBLIC_URL + '/tasks/task-123/gmail.png'}/>
                    <label className="checkbox-container">
                        <input type="checkbox" />&nbsp;
                        <span className="checkmark"></span>
                    </label>
                </div>
            </div>
            <div>
                <input type="text" placeholder="wpisz tutaj"></input>
            </div>
        </div>
    )
}

export default TaskGame123;
