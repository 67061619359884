import { useUserModuleDispatch, USER_MODULE_ACTION } from "..";

export default function useReset() {
    const dispatch = useUserModuleDispatch();

    return () => {
        dispatch({
            type: USER_MODULE_ACTION.RESET
        });
    }
}
