import React from "react";
import './style.scss';

export default function PageFooter() {
    return (
        <footer className="page-footer">
            <header>
                <h3>2021 © projekt.szkola@innowatorium.org</h3>
            </header>            
            <div className="col-3">
                <a href="https://www.um.warszawa.pl/" target="_blank">
                    <img src={`${process.env.PUBLIC_URL}/images/footer/logo_zakochajsiewwarszawie.png`} alt="Zakochaj się w Warszawie" />
                </a>
                <a href="http://innowatorium.org/" target="_blank">
                    <img src={`${process.env.PUBLIC_URL}/images/footer/logo_innowatorium.png`} alt="Innowatorium" />
                </a>
                <a href="https://www.gdevents.pl/" target="_blank">
                    <img src={`${process.env.PUBLIC_URL}/images/footer/logo_gdevents.png`} alt="GDEvents" />
                </a>                
            </div>        
        </footer>
    )
}
