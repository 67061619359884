import React, { useCallback, useReducer, useMemo, useEffect } from "react";
import PickUp from "../../drag-drop-template/pick-up";
import DropZone from "../../drag-drop-template/drop-zone";
import './style.scss';

type Props = {
    onComplete?: () => void
}

type Item = {
    value: string,
    image: string
}

type Basket = {
    id: string,
    answer?: string,
    items: string[],
    label: string
}

type GameState = {
    baskets: Basket[],
    items: Item[]
}

const TaskGame142: React.FunctionComponent<Props> = ({ onComplete }) => {

    const [gameData, moveEgg] = useReducer((state: GameState, { itemId, basket }: { itemId: string, basket: string }) => {
        return {
            ...state,
            baskets: state.baskets.map(b => {

                const items = b.items.filter(i => i !== itemId);

                if (b.id === basket) {
                    if (state.items.find(i => i.value === itemId)) {
                        items[0] = itemId;
                    }
                }

                return {
                    ...b,
                    items
                }
            })
        }
    }, {
        baskets: [
            {
                id: 'b1',
                answer: 'i7',
                items: [],
                label: 'Buk'
            },
            {
                id: 'b2',
                answer: 'i1',
                items: [],
                label: 'Jesion'
            },
            {
                id: 'b3',
                answer: 'i3',
                items: [],
                label: 'Topola'
            },
            {
                id: 'b4',
                answer: 'i6',
                items: [],
                label: 'Olcha'
            }            

        ],
        items: [
            {
                value: 'i1',
                image: process.env.PUBLIC_URL + '/tasks/task-142/zd_1.png'
            },
            {
                value: 'i2',
                image: process.env.PUBLIC_URL + '/tasks/task-142/zd_2.png'
            },
            {
                value: 'i3',
                image: process.env.PUBLIC_URL + '/tasks/task-142/zd_3.png'
            },
            {
                value: 'i4',
                image: process.env.PUBLIC_URL + '/tasks/task-142/zd_4.png'
            }            
        ]
    })

    // const completed = useMemo(() => {
    //     return gameData.baskets.reduce((prev, b) => {
    //         return prev && (b.answer === b.items[0]);
    //     }, true);
    // }, [gameData.baskets]);

    // useEffect(() => {
    //     if (completed) {
    //         if (onComplete) onComplete();
    //     }
    // }, [completed, onComplete])

    const onDrop = useCallback((pickupData: string, dropId: string) => {
        moveEgg({ itemId: pickupData, basket: dropId });
    }, [])

    const isUnused = useCallback((value) => {
        return gameData.baskets.reduce((prev, next) => {
            return prev && Boolean(next.items[0] !== value);
        }, true);
    }, [gameData.baskets])


    return (
        <div className="task-game-142"> 
            <div className="column-pick">
                <div className="pick">
                    {
                        isUnused('i1') && <GameElement isPickable={true} item={gameData.items.find(i => i.value === 'i1')}></GameElement>
                    }
                </div>      
                <div className="pick">
                    {
                        isUnused('i2') && <GameElement isPickable={true} item={gameData.items.find(i => i.value === 'i2')}></GameElement>
                    }
                </div>   
                <div className="pick">
                    {
                        isUnused('i3') && <GameElement isPickable={true} item={gameData.items.find(i => i.value === 'i3')}></GameElement>
                    }
                </div>
                <div className="pick">
                    {
                        isUnused('i4') && <GameElement isPickable={true} item={gameData.items.find(i => i.value === 'i4')}></GameElement>
                    }
                </div>    
            </div>
            <div className="column-drop">
                <GameDropZone id="b1" game={gameData} onDrop={onDrop}></GameDropZone>
                <GameDropZone id="b2" game={gameData} onDrop={onDrop}></GameDropZone>
                <GameDropZone id="b3" game={gameData} onDrop={onDrop}></GameDropZone>        
                <GameDropZone id="b4" game={gameData} onDrop={onDrop}></GameDropZone>   
            </div>         
        </div>
    )
}

const GameElement: React.FunctionComponent<{ item?: Item, isPickable: boolean }> = ({ item, isPickable }) => {

    return (
        <>
            {item && <div className="game-element">
                {isPickable && <PickUp
                    data={item.value}
                >
                    <img src={item.image} alt="image" />
                </PickUp>}
                {!isPickable && <img src={item.image} alt="image" />}
            </div>}
        </>
    )
}

const GameDropZone: React.FunctionComponent<{
    id: string,
    game: GameState,
    onDrop: (pickupData: string, dropId: string) => void;
}> = ({ id, onDrop, game, children }) => {

    const basket = game.baskets.find(b => b.id === id);
    const itemValue = basket?.items[0] || null;
    const item = game.items.find(i => i.value === itemValue) || null;

    const state = useMemo(() => {
        if (!item) {
            return 'empty'
        }
        return 'not-empty';
    }, [basket, item])

    return (
        <div className={`game-drop-zone ${state}`}>
            <div className="inner">
                <DropZone onDrop={onDrop} id={id}>
                    {
                        item && <GameElement item={item} isPickable={true} ></GameElement>
                    }
                    {
                        !item && <div className="placeholder"><span>przeciągnij tutaj</span></div>
                    }
                </DropZone>
            </div>
        </div>
    )
}

export default TaskGame142;
