import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch, useLocation, Link } from 'react-router-dom';

import HomePage from './routes/home';

import { getHomeRoute, getMapRoute, getTaskRoute, getRulesRoute, getManualRoute, getLoginRoute, TASK_TO_URI_TABLE, getEndRoute, getCategoriesRoute } from './routes';
import MapPage from './routes/map';
import TaskPage from './routes/task';
import PageBackground from './layout/background';
import RulesPage from './routes/rules';
import ManualPage from './routes/manual';
import CategoriesPage from './routes/categories';
import LoginPage from './routes/login';
import LandingPage from './routes/landing-page';
import EndPage from './routes/end';
import Cookies from './components/cookies';
import { useAcceptedCookies } from './modules/main';
import ScrollToTop from '@mborecki/react-scroll-to-top';
import useUser from './modules/user/hooks/use-user';
import useReset from './modules/user/hooks/use-reset';

export default function App() {

    return (
        <PageBackground>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <RouterInsider />
            </BrowserRouter>
        </PageBackground>
    )
}

const RouterInsider = () => {

    const isAccepedCookies = useAcceptedCookies()

    const { name } = useUser();

    const isLogged = Boolean(name)

    const showCookiePopup = !isAccepedCookies;

    const {pathname} = useLocation();

    return (
        <>
            <ScrollToTop trigger={pathname} />
            {showCookiePopup && <Cookies />}
            {
                <Switch>
                    <Route path={getHomeRoute()} exact>
                        <HomePage />
                    </Route>
                    <Route path="/reset">
                        <ResetGame></ResetGame>
                    </Route>
                    <Route path={getManualRoute()}>
                        <ManualPage />
                    </Route>
                    <Route path={getCategoriesRoute()}>
                        <CategoriesPage />
                    </Route>
                    <Route path={getRulesRoute()}>
                        <RulesPage />
                    </Route>
                    <Route path={getLoginRoute()}>
                        <LoginPage />
                    </Route>

                    <Route path={getMapRoute()}>
                        <MapPage />
                    </Route>
                    {isLogged && <>
                        {TASK_TO_URI_TABLE.map((data, index) => {
                            return (
                                <Route key={index} path={data[1]}>
                                    <TaskPage id={data[0]} />
                                </Route>
                            )
                        })
                        }
                        <Route path={getTaskRoute()}>
                            <TaskPage />
                        </Route>
                        <Route path={getManualRoute()}>
                            <ManualPage />
                        </Route>
                        <Route path='/lp-demo'>
                            <LandingPage />
                        </Route>
                        <Route path={getEndRoute()}>
                            <EndPage />
                        </Route>
                    </>}
                    <Route><HomePage /></Route>
                </Switch>
            }
        </>
    )
}


const ResetGame = () => {
    const reset = useReset();

    useEffect(() => {
        reset();
    }, [reset])


    return (<>
        <Link to="/">START</Link>
    </>)
}
