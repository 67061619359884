import React, { useState, useEffect } from 'react';
// import MapV2 from '../../components/map2';
import { useLocation } from 'react-router';
import './style.scss'
import useUserCompletedTasks from '../../modules/user/hooks/use-user-completed-tasks';
import useSetUserCompleteTask from '../../modules/user/hooks/use-set-user-complete-task';

import Popup from './task-end-popup';
import MapV2 from '../../components/map2';
import { useSetSolidColorBackground } from '../../layout/background';
import CenterLolumnLayout from '../../layout/screen';
import PageFooter from '../../components/page-footer';

export default function MapPage() {
    const [popupInfo, setPopup] = useState<{ isShow: Boolean, task?: Task, onClose?: () => void }>({
        isShow: false
    })

    const { state } = useLocation<{ task: Task }>()

    const setBackground = useSetSolidColorBackground();
    useEffect(() => {
        setBackground('#ffffff');
    }, [setBackground]);


    const comletedTasks = useUserCompletedTasks();
    const setCompletedTask = useSetUserCompleteTask();

    useEffect(() => {
        if (state) {
            if (comletedTasks.indexOf(state.task.id) === -1) {
                setPopup({
                    isShow: true,
                    task: state.task,
                    onClose: () => {
                        setCompletedTask(state.task.id);
                    }
                })
            }
        }

        document.body.classList.add('map-page');
        return () => {
            document.body.classList.remove('map-page');
        }

    }, [state, setPopup, setCompletedTask, comletedTasks])

    return (
        <CenterLolumnLayout footer={PageFooter()}>
            <main className='column map-screen'>        
                <h1>Projekt: Szkoła (w domu!)</h1>
                <MapV2 />
                {popupInfo.isShow && popupInfo.task && <Popup
                    task={popupInfo.task}
                    onClick={() => {
                        if (popupInfo.onClose) popupInfo.onClose();
                        setPopup({ isShow: false })
                    }}
                />}
            </main>
        </CenterLolumnLayout>
    )
}

