import React, { useEffect } from "react";
import { BACKGROUND_TYPE, useBackgroundState, useBackgroundDispacher } from ".";



export type SolidColorBackgroundState = {
    type: BACKGROUND_TYPE.SOLID_COLOR,
    color: string
}

const SolidBodyBackgound: React.FunctionComponent = ({ children }) => {

    const {color} = useBackgroundState() as SolidColorBackgroundState;

    useEffect(() => {
        window.document.body.style.backgroundColor = color;
        window.document.body.style.backgroundImage = '';
        return;
    }, [color])

    return (<>{children}</>);
}

export function useSetSolidColorBackground() {
    const dispatch = useBackgroundDispacher();
    return (color: string) => {
        dispatch({
            type: 'set',
            newState: {
                type: BACKGROUND_TYPE.SOLID_COLOR,
                color
            }
        })
    }
}

export default SolidBodyBackgound;
