import React, { useCallback, useState } from "react";
import './style.scss';

type Props = {
    onComplete?: () => void
}

const TaskGame131: React.FunctionComponent<Props> = () => {

    return (
        <div className="task-game-131">
            <div>
                <div className="item">
                    <img src={process.env.PUBLIC_URL + '/tasks/task-131/ulica.png'}/>
                    <label className="checkbox-container">
                        <input type="checkbox" />&nbsp;
                        <span className="checkmark"></span>
                    </label>
                </div>
                <div className="item">
                    <img src={process.env.PUBLIC_URL + '/tasks/task-131/park.png'}/>
                    <label className="checkbox-container">
                        <input type="checkbox" />&nbsp;
                        <span className="checkmark"></span>
                    </label>
                </div>
                <div className="item">
                    <img src={process.env.PUBLIC_URL + '/tasks/task-131/blokowiska.png'}/>
                    <label className="checkbox-container">
                        <input type="checkbox" />&nbsp;
                        <span className="checkmark"></span>
                    </label>
                </div>                
            </div>
        </div>
    )
}

export default TaskGame131;
